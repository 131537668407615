import SubHeader from './SubHeader'
import MainHeader from './MainHeader'

const Header = () => {
  return (
    <>
      <MainHeader />
      <SubHeader />
    </>
  )
}

export default Header
