import React, { useEffect, useRef, useState } from 'react'
import { GeoJSON, Tooltip, useMap } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'

import { searchActionReducer } from '../../../store/search/searchSlice'

const NeighborhoodPolygon = ({ neighborhood, selected, refetchListings, setActivePolygonRefs }) => {
  const dispatch = useDispatch()
  const polygonRef = useRef()

  const { neighborhoods: selectedNeighborhoods, hoveredNeighborhood } = useSelector((state) => state.search)
  const hovered = hoveredNeighborhood === neighborhood?.name && selectedNeighborhoods?.length > 1

  const setNeighborhoods = (payload) => dispatch(searchActionReducer.setNeighborhoods(payload))
  const setHoveredNeighborhood = (payload) => dispatch(searchActionReducer.setHoveredNeighborhood(payload))

  const fillColor = hovered ? '#92CEDC60' : selected ? '#92CEDC38' : '#92CEDC14'
  const strokeColor = selected || hovered ? '#85C7D6' : '#92CEDC80'

  const handlePolygonOnClick = () => {
    const newNeighborhoods = selectedNeighborhoods?.includes(neighborhood?.uuid)
      ? selectedNeighborhoods?.filter((neighborhoodUUID) => neighborhood?.uuid !== neighborhoodUUID)
      : [neighborhood?.uuid, ...selectedNeighborhoods]
    setNeighborhoods(newNeighborhoods)
    refetchListings(
      {
        building__neighborhood__uuid__in: newNeighborhoods.join(',') || null,
      },
      'NeighborhoodPolygon.handlePolygonOnClick',
    )
  }

  useEffect(() => {
    setActivePolygonRefs((activePolygonRefs) =>
      selected
        ? !activePolygonRefs?.includes(polygonRef)
          ? [polygonRef, ...activePolygonRefs]
          : null
        : activePolygonRefs?.filter((activePolygonRef) => activePolygonRef !== polygonRef),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleMouseOver = () => setHoveredNeighborhood(neighborhood?.name)
  const handleMouseOut = () => setHoveredNeighborhood(null)

  return (
    <GeoJSON
      key={Math.random()}
      ref={polygonRef}
      data={neighborhood.geojson}
      color={strokeColor}
      fillColor={fillColor}
      hoveredStyle={{ color: '#85C7D6', fillColor: '#92CEDC60' }}
      weight={2}
      fillOpacity={1}
      eventHandlers={{
        click: handlePolygonOnClick,
        // mouseover: handleMouseOver,
        // mouseout: handleMouseOut,
      }}
    >
      <Tooltip sticky>{neighborhood.name}</Tooltip>
    </GeoJSON>
  )
}

const NeighborhoodPolygons = ({ refetchListings }) => {
  const map = useMap()
  const allNeighborhoods = useSelector((state) => state.neighborhood)
  const { neighborhoods: selectedNeighborhoods } = useSelector((state) => state?.search)
  const [activePolygonRefs, setActivePolygonRefs] = useState([])
  const [mapBounds, setMapBounds] = useState([])

  useEffect(() => {
    const newMapBounds = activePolygonRefs?.map((activePolygonRef) => activePolygonRef.current.getBounds())
    if (!map || !activePolygonRefs?.length || newMapBounds === mapBounds) return
    setMapBounds(newMapBounds)
    map.fitBounds(newMapBounds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePolygonRefs])

  const neighborhoodPolygons = allNeighborhoods?.map((neighborhood) => (
    <NeighborhoodPolygon
      key={neighborhood?.uuid}
      neighborhood={neighborhood}
      selected={selectedNeighborhoods?.includes(neighborhood?.uuid)}
      refetchListings={refetchListings}
      setActivePolygonRefs={setActivePolygonRefs}
    />
  ))

  return neighborhoodPolygons
}

export default NeighborhoodPolygons
