function groupAndSortAddresses(addresses) {
  // Create an object to store addresses grouped by streetName
  const groupedAddresses = {}

  // Loop through the addresses
  addresses?.forEach((address) => {
    const streetName = address.streetName

    // If the streetName is not already a key in the groupedAddresses object, create an empty object for it
    if (!groupedAddresses[streetName]) {
      groupedAddresses[streetName] = {
        addresses: [],
        minNumber: Infinity,
        maxNumber: -Infinity,
      }
    }

    // Push the address into the corresponding group
    groupedAddresses[streetName].addresses.push(address)

    // Update min and max numbers
    if (address.streetNumber < groupedAddresses[streetName].minNumber) {
      groupedAddresses[streetName].minNumber = address.streetNumber
    }
    if (address.streetNumber > groupedAddresses[streetName].maxNumber) {
      groupedAddresses[streetName].maxNumber = address.streetNumber
    }
  })

  // Sort addresses within each group by streetNumber
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      groupedAddresses[streetName].addresses.sort((a, b) => a.streetNumber - b.streetNumber)
    }
  }

  // Convert the groupedAddresses object to the desired format
  const formattedAddresses = []
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      const { addresses, minNumber, maxNumber } = groupedAddresses[streetName]
      formattedAddresses.push({
        streetName: streetName,
        addresses: addresses,
        minNumber: minNumber,
        maxNumber: maxNumber,
      })
    }
  }

  return formattedAddresses
}

function transformToSchemaOrg(listing) {
  const address = listing?.address?.value
  const name = `Unit ${listing?.unit?.number} in ${listing?.building?.name} in ${listing?.neighborhood?.name} Miami at ${address}`

  const schemaOrgJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Apartment',
    name: name,
    description: listing?.description,
    floorSize: {
      '@type': 'QuantitativeValue',
      value: listing?.unit?.squareFootage,
      unitCode: 'FTK',
    },
    numberOfBathroomsTotal: listing?.unit?.numberOfBathrooms,
    numberOfBedrooms: listing?.unit?.numberOfBedrooms,
    yearBuilt: listing?.building?.yearBuilt,
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'US',
      addressLocality: listing?.address?.city,
      addressRegion: 'Florida',
      postalCode: listing?.address?.zipcode,
      streetAddress: `${listing?.address?.streetNumber} ${listing?.address?.streetName} Unit ${listing?.unit?.number}`,
    },
    image: listing?.medias?.slice(0, 3).map((media) => media?.file),
  }

  return schemaOrgJsonLd
}

export default transformToSchemaOrg
