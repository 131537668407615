import React from 'react'
import 'semantic-ui-css/semantic.min.css'

import PriceFilter from './PriceFilter'
import BedsFilter from './BedsFilter'
import NeighborhoodsFilter from './NeighborhoodsFilter'
import { FilterContainer } from './FilterBar.style'
import SearchButton from './SearchButton'

const FilterBar = () => (
  <FilterContainer>
    <NeighborhoodsFilter />
    <BedsFilter />
    <PriceFilter />
    <SearchButton />
  </FilterContainer>
)

export default FilterBar
