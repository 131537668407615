import ReactGA from 'react-ga4'

import {
  ListingCardContent,
  Icon,
  UnitDetails,
  UnitTitle,
  ListingCardLink,
  ListingCardContainer,
  ListingCardImage,
  IconImage,
} from './ListingCard.style'
import { BED_ICON, BATH_ICON, SQURE_FOOT_ICON } from '../../../../../../../constants/image.const'
import clickEvents from '../../../../../../../services/analytics.service'

const ListingCard = ({ listing }) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumSignificantDigits: 1,
    notation: 'standard',
  })

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 1,
  })

  const handleListingLinkOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'search-page-popup-listing-card-clicked',
    })
    clickEvents.POPUP_LISTING_CARD()
  }

  return (
    <ListingCardContainer>
      <ListingCardLink to={listing?.url} onClick={handleListingLinkOnClick}>
        <ListingCardImage src={listing.medias[0]?.file} alt="listing" />
        <ListingCardContent>
          <UnitTitle>
            <div>Unit {listing?.unit?.number}</div>
            <div>{currencyFormatter.format(listing?.price)}</div>
          </UnitTitle>
          <UnitDetails>
            <Icon>
              <IconImage src={BED_ICON} alt="beds" />
              {listing?.unit?.numberOfBedrooms} Beds
            </Icon>
            <Icon>
              <IconImage src={BATH_ICON} alt="baths" />
              {listing?.unit?.numberOfBathrooms} Baths
            </Icon>
            <Icon>
              <IconImage src={SQURE_FOOT_ICON} alt="fts" />
              {numberFormatter.format(listing?.unit?.squareFootage)} ft²
            </Icon>
          </UnitDetails>
        </ListingCardContent>
      </ListingCardLink>
    </ListingCardContainer>
  )
}

export default ListingCard
