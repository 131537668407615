import React from 'react'
import { AddressContainer, StyledTooltip, WrapInformation } from './MarkerHover.style'
import { Typography } from '@mui/material'
import { ReactComponent as GROUPING_ICON } from '../../../../../../../assets/images/group-building.svg'

function groupAndSortAddresses(addresses) {
  // Create an object to store addresses grouped by streetName
  const groupedAddresses = {}

  // Loop through the addresses
  addresses?.forEach((address) => {
    const streetName = address.streetName

    // If the streetName is not already a key in the groupedAddresses object, create an empty object for it
    if (!groupedAddresses[streetName]) {
      groupedAddresses[streetName] = {
        addresses: [],
        minNumber: Infinity,
        maxNumber: -Infinity,
      }
    }

    // Push the address into the corresponding group
    groupedAddresses[streetName].addresses.push(address)

    // Update min and max numbers
    if (address.streetNumber < groupedAddresses[streetName].minNumber) {
      groupedAddresses[streetName].minNumber = address.streetNumber
    }
    if (address.streetNumber > groupedAddresses[streetName].maxNumber) {
      groupedAddresses[streetName].maxNumber = address.streetNumber
    }
  })

  // Sort addresses within each group by streetNumber
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      groupedAddresses[streetName].addresses.sort((a, b) => a.streetNumber - b.streetNumber)
    }
  }

  // Convert the groupedAddresses object to the desired format
  const formattedAddresses = []
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      const { addresses, minNumber, maxNumber } = groupedAddresses[streetName]
      formattedAddresses.push({
        streetName: streetName,
        addresses: addresses,
        minNumber: minNumber,
        maxNumber: maxNumber,
      })
    }
  }

  return formattedAddresses
}

const MarkerHover = ({ building }) => {
  const title = building?.name

  const addresses = groupAndSortAddresses(building?.addresses)

  const extraAddresses = addresses?.length > 3
  const addressCards = addresses?.slice(0, 2).map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]
    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  return (
    <StyledTooltip direction="top" opacity="1" offset={[0, -38]}>
      <GROUPING_ICON />
      <WrapInformation>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="h6">
          <AddressContainer>
            {extraAddresses ? addressCards.slice(0, 2) : addressCards}
            {extraAddresses && <span>+{addresses?.length - 2} locations</span>}
          </AddressContainer>
        </Typography>
        {/* <Typography variant="h7">Click for details</Typography> */}
      </WrapInformation>
    </StyledTooltip>
  )
}

export default MarkerHover
