import React, { useState } from 'react'
import ReactGA from 'react-ga4'

import OCEAN_PADS_BANNER from '../../../../assets/images/hero/Skyline v3_LB_City copy.svg'
import SlideShow from '../../../SlideShow'
import {
  Image,
  ImageGridContainer,
  ImagesContainer,
  PhotoBadge,
  PrimaryImageContainer,
  SecondaryImageContainer,
  StatusBadge,
} from './Images.style'
import clickEvents from '../../../../services/analytics.service'

export const Images = ({ listing }) => {
  const [slideShowIsOpen, setSlideShowIsOpen] = useState(false)

  const sortedListingMedias = listing?.medias?.sort((a, b) => a.order - b.order)

  const handleImageOnClick = () => {
    setSlideShowIsOpen(true)
    ReactGA.event({
      category: 'action',
      action: `listing-page-listing-image-clicked`,
    })
    clickEvents.LISTING_IMAGE()
  }

  const primaryAlt = `Primary photo of unit ${listing?.unit?.number} in ${listing?.building?.name}`

  const secondaryImages = sortedListingMedias?.slice(1, 5).map((item, index) => {
    const secondaryAlt = `Photo ${index + 2} of unit ${listing?.unit?.number} in ${listing?.building?.name}`
    const secondaryImgSrc = item?.file?.replace(
      'https://dvvjkgh94f2v6.cloudfront.net/',
      'https://ik.imagekit.io/y4kqb1wlh/',
    )
    return (
      !!secondaryImgSrc && (
        <SecondaryImageContainer key={index} onClick={handleImageOnClick}>
          <Image src={secondaryImgSrc} alt={secondaryAlt} />
        </SecondaryImageContainer>
      )
    )
  })

  const numberOfImages = sortedListingMedias?.length
  const listingHasImages = !!numberOfImages
  const firstImage = listingHasImages && sortedListingMedias[0]
  const firstImageSrc =
    firstImage?.file?.replace('https://dvvjkgh94f2v6.cloudfront.netl/', 'https://ik.imagekit.io/y4kqb1wlh/') ||
    OCEAN_PADS_BANNER
  const numberOfExtraPhotos = sortedListingMedias?.length - secondaryImages?.length - 1
  const listingHasMorePhotos = !!numberOfExtraPhotos

  return (
    <ImagesContainer>
      <PrimaryImageContainer>
        <Image onClick={handleImageOnClick} src={firstImageSrc} alt={primaryAlt} />
      </PrimaryImageContainer>
      <ImageGridContainer>
        {secondaryImages}
        {listingHasMorePhotos && <PhotoBadge onClick={handleImageOnClick}>+ {numberOfExtraPhotos} photos</PhotoBadge>}
      </ImageGridContainer>
      {slideShowIsOpen && <SlideShow setSlideShowIsOpen={setSlideShowIsOpen} images={sortedListingMedias} />}
      {listing?.status === 'CLOSED' && <StatusBadge>Off Market</StatusBadge>}
    </ImagesContainer>
  )
}

export default Images
