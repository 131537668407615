import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import useAxios from 'axios-hooks'

import { FLOORS_ICON, UNIT_ICON, PYRAMID_ICON } from '../../../../constants/image.const'
import { CONSUMER_SAVED_BUILDINGS } from '../../../../constants/api'
import Images from './Images'
import {
  Button,
  ButtonsContainer,
  CardContainer,
  Icon,
  IconImage,
  IconsContainer,
  ItemContainer,
  ItemDataContainer,
  ItemLowerHalfContainer,
  ItemSubtitle,
  TextElement,
} from './Item.style'

function groupAndSortAddresses(addresses) {
  // Create an object to store addresses grouped by streetName
  const groupedAddresses = {}

  // Loop through the addresses
  addresses.forEach((address) => {
    const streetName = address.streetName

    // If the streetName is not already a key in the groupedAddresses object, create an empty object for it
    if (!groupedAddresses[streetName]) {
      groupedAddresses[streetName] = {
        addresses: [],
        minNumber: Infinity,
        maxNumber: -Infinity,
      }
    }

    // Push the address into the corresponding group
    groupedAddresses[streetName].addresses.push(address)

    // Update min and max numbers
    if (address.streetNumber < groupedAddresses[streetName].minNumber) {
      groupedAddresses[streetName].minNumber = address.streetNumber
    }
    if (address.streetNumber > groupedAddresses[streetName].maxNumber) {
      groupedAddresses[streetName].maxNumber = address.streetNumber
    }
  })

  // Sort addresses within each group by streetNumber
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      groupedAddresses[streetName].addresses.sort((a, b) => a.streetNumber - b.streetNumber)
    }
  }

  // Convert the groupedAddresses object to the desired format
  const formattedAddresses = []
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      const { addresses, minNumber, maxNumber } = groupedAddresses[streetName]
      formattedAddresses.push({
        streetName: streetName,
        addresses: addresses,
        minNumber: minNumber,
        maxNumber: maxNumber,
      })
    }
  }

  return formattedAddresses
}

const Item = ({ building }) => {
  const [favoriteIsLoading, setFavoriteIsLoading] = useState(false)
  const [isFavorited, setIsFavorited] = useState(true)

  const addresses = groupAndSortAddresses(building?.addresses)

  const addressCards = addresses?.map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]

    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  const types = {
    CONDOMINIUM: 'Condominium Building',
    APARTMENT: 'Apartment Building',
  }

  const type = types[building?.type] || 'Building'

  const [, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_BUILDINGS,
      method: 'POST',
      data: {
        building: building?.building,
      },
    },
    { manual: true },
  )

  const [, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_BUILDINGS}${building?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const handleFavoriteOnClick = () => {
    if (favoriteIsLoading) return
    setFavoriteIsLoading(true)
    if (isFavorited)
      removeFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(false)
      })
    else
      addFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(true)
      })
  }

  return (
    <ItemContainer>
      <CardContainer>
        <Link to={building?.url}>
          <Images building={building} />
          <ItemLowerHalfContainer>
            <ItemDataContainer>
              <TextElement>
                <ItemSubtitle>{building?.buildingName}</ItemSubtitle>
                <span>
                  {type} in {building?.neighborhoodName}
                </span>
                {addressCards}
              </TextElement>
            </ItemDataContainer>
            <IconsContainer>
              <Icon>
                <IconImage src={FLOORS_ICON} alt="floors-icon" />
                {building?.numberOfUnits ? `${building?.numberOfFloors} Floors` : 'Floors unknown'}
              </Icon>
              <Icon>
                <IconImage src={UNIT_ICON} alt="unit-icon" />
                {building?.numberOfUnits ? `${building?.numberOfUnits} Units` : 'Units unknown'}
              </Icon>
              <Icon>
                <IconImage src={PYRAMID_ICON} alt="pyramid-icon" />
                {building?.yearBuilt ? `Built in ${building?.yearBuilt}` : 'Built unknown'}
              </Icon>
            </IconsContainer>
          </ItemLowerHalfContainer>
        </Link>
      </CardContainer>
      <ButtonsContainer>
        <Button onClick={handleFavoriteOnClick} isFavorited={isFavorited}>
          {favoriteIsLoading ? (
            <Loader size="mini" inline="centered" active color="white" inverted={isFavorited} />
          ) : isFavorited ? (
            'Remove'
          ) : (
            'Restore'
          )}
        </Button>
      </ButtonsContainer>
    </ItemContainer>
  )
}

export default Item
