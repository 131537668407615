import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useOutsideClick from '../../../../hooks/outsideClick'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import PriceFilterDropdown from './PriceFilterDropdown'
import { ContainerTitle } from '../Containers/Containers.style'
import { PriceFilterContainer, PriceFilterTriggerContainer, PriceOptionsContainer } from './PriceFilter.style'

const PriceFilter = () => {
  const closeIconRef = useRef()
  const containerRef = useRef()

  const dispatch = useDispatch()

  const { maxPrice, minPrice, type } = useSelector((state) => state.search)
  const { minPriceInput, maxPriceInput } = useSelector((state) => state.search)

  const setMaxPrice = (payload) => dispatch(searchActionReducer.setMaxPrice(payload))
  const setMinPrice = (payload) => dispatch(searchActionReducer.setMinPrice(payload))
  const setMaxPriceInput = (payload) => dispatch(searchActionReducer.setMaxPriceInput(payload))
  const setMinPriceInput = (payload) => dispatch(searchActionReducer.setMinPriceInput(payload))

  const [priceFilterIsOpen, setPriceFilterIsOpen] = useState(false)

  const maxSalePriceOptions = () => {
    return [
      { value: 250000, label: '250000' },
      { value: 500000, label: '500000' },
      { value: 1000000, label: '1000000' },
      { value: 2000000, label: '2000000' },
      { value: 5000000, label: '5000000' },
      { value: 10000000, label: '10000000' },
      { value: 20000000, label: '20000000' },
      { value: 50000000, label: '50000000' },
      { value: 100000000, label: '100000000' },
    ]
  }

  const minSalePriceOptions = () => {
    return [
      { value: 100000, label: '100000' },
      { value: 150000, label: '150000' },
      { value: 200000, label: '200000' },
      { value: 300000, label: '300000' },
      { value: 500000, label: '500000' },
      { value: 1000000, label: '1000000' },
      { value: 2000000, label: '2000000' },
      { value: 5000000, label: '5000000' },
      { value: 10000000, label: '10000000' },
    ]
  }

  const maxRentPriceOptions = () => {
    return [
      { value: 1500, label: '1500' },
      { value: 2000, label: '2000' },
      { value: 2500, label: '2500' },
      { value: 3000, label: '3000' },
      { value: 5000, label: '5000' },
      { value: 7500, label: '7500' },
      { value: 10000, label: '10000' },
      { value: 15000, label: '15000' },
      { value: 30000, label: '30000' },
    ]
  }

  const minRentPriceOptions = () => {
    return [
      { value: 500, label: '500' },
      { value: 1000, label: '1000' },
      { value: 1500, label: '1500' },
      { value: 2000, label: '2000' },
      { value: 2500, label: '2500' },
      { value: 3000, label: '3000' },
      { value: 5000, label: '5000' },
      { value: 7500, label: '7500' },
      { value: 10000, label: '10000' },
    ]
  }

  const minPriceOptions = type === 'FOR_RENT' ? minRentPriceOptions() : minSalePriceOptions()
  const maxPriceOptions = type === 'FOR_RENT' ? maxRentPriceOptions() : maxSalePriceOptions()

  const handleCloseIconOnClick = () => {
    setMinPrice(null)
    setMaxPrice(null)
    setMinPriceInput('')
    setMaxPriceInput('')
  }

  const handlePriceFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setPriceFilterIsOpen((priceFilterIsOpen) => !priceFilterIsOpen)
  }

  useOutsideClick(
    containerRef,
    () => {
      if (!priceFilterIsOpen) return
      setPriceFilterIsOpen(false)
    },
    [priceFilterIsOpen, minPrice, maxPrice],
  )

  return (
    <PriceFilterContainer ref={containerRef} isOpen={priceFilterIsOpen}>
      <PriceFilterTriggerContainer onClick={handlePriceFilterOnClick}>
        <ContainerTitle>Price</ContainerTitle>
        <PriceOptionsContainer>
          <PriceFilterDropdown
            options={minPriceOptions}
            placeholder="Min"
            title="Min Price"
            priceValue={minPrice}
            setPriceValue={setMinPrice}
            inputValue={minPriceInput}
            setInputValue={setMinPriceInput}
          />
          <PriceFilterDropdown
            options={maxPriceOptions}
            placeholder="Max"
            title="Max Price"
            priceValue={maxPrice}
            setPriceValue={setMaxPrice}
            inputValue={maxPriceInput}
            setInputValue={setMaxPriceInput}
          />
        </PriceOptionsContainer>
      </PriceFilterTriggerContainer>
    </PriceFilterContainer>
  )
}

export default PriceFilter
