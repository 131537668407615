import { useRef, useState } from 'react'

import { CloseIcon, DownArrow } from '../../Containers'
import useOutsideClick from '../../../../../hooks/outsideClick'
import {
  IconContainer,
  IconsContainer,
  MoreFilterDropdownContainer,
  MoreOptionContainer,
  MoreOptionsContainer,
  MoreSubtitleContainer,
  MoreValueContainer,
  MoreValueInputContainer,
} from './MoreFilterDropdown.style'

const MoreFilterDropdown = ({ options, placeholder, title, moreValue, setMoreValue, inputValue, setInputValue }) => {
  const dropdownRef = useRef()
  const inputRef = useRef()

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const closeIconIsHidden = !inputValue

  const handleInputOnClick = (event) => {
    setMoreValue(null)
    setInputValue('')
    setDropdownIsOpen((dropdownIsOpen) => !dropdownIsOpen)
  }

  const handleInputOnChange = (event) => {
    const value = event?.target?.value?.replace(/\D/g, '')
    setMoreValue(value)
    setDropdownIsOpen(true)
    setInputValue(value)
  }

  const handleInputOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      inputRef?.current?.blur()
      setDropdownIsOpen(false)
      const remainingOptions = options?.filter((option) => !moreValue || `${option?.value}`?.includes(moreValue))
      if (!remainingOptions?.length) {
        setInputValue('')
        setMoreValue(null)
      } else {
        const firstOption = remainingOptions[0]
        setInputValue(firstOption?.label)
        setMoreValue(firstOption?.value)
      }
    }
  }

  const handleCloseIconOnClick = () => {
    inputRef?.current?.blur()
    setDropdownIsOpen(false)
    setMoreValue(null)
    setInputValue('')
  }

  const handleDownArrowOnClick = () => {
    setDropdownIsOpen(true)
  }

  const handleOptionOnClick = (option) => {
    setMoreValue(option?.value)
    setInputValue(option?.label)
    setDropdownIsOpen(false)
  }

  useOutsideClick(
    dropdownRef,
    () => {
      setDropdownIsOpen(false)
    },
    [inputValue],
  )

  const moreOptions = options
    ?.filter((option) => !moreValue || `${option?.value}`?.includes(moreValue))
    ?.map((option) => (
      <MoreOptionContainer key={option?.value} onClick={() => handleOptionOnClick(option)}>
        {option?.label}
      </MoreOptionContainer>
    ))

  return (
    <MoreFilterDropdownContainer ref={dropdownRef}>
      <MoreSubtitleContainer>{title}</MoreSubtitleContainer>
      <MoreValueContainer isOpen={dropdownIsOpen}>
        <MoreValueInputContainer
          ref={inputRef}
          placeholder={placeholder}
          onChange={handleInputOnChange}
          value={inputValue}
          onKeyDown={handleInputOnKeyDown}
          onClick={handleInputOnClick}
        />
        <IconsContainer>
          <IconContainer isHidden={closeIconIsHidden} onClick={handleCloseIconOnClick}>
            <CloseIcon />
          </IconContainer>
          <IconContainer onClick={handleDownArrowOnClick}>
            <DownArrow />
          </IconContainer>
        </IconsContainer>
      </MoreValueContainer>
      <MoreOptionsContainer isOpen={dropdownIsOpen}>{moreOptions}</MoreOptionsContainer>
    </MoreFilterDropdownContainer>
  )
}

export default MoreFilterDropdown
