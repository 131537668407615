import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ContainerTitle } from '../Containers/Containers.style'

import MoreFilterDropdown from './MoreFilterDropdown'
import useOutsideClick from '../../../../hooks/outsideClick'
import {
  MoreCheckboxesContainer,
  MoreFilterContainer,
  MoreFilterTriggerContainer,
  MoreOption,
  MoreOptionCheckbox,
  MoreOptionLabel,
  MoreOptionSubtitle,
} from './MoreFilter.style'

const MoreFilter = () => {
  const closeIconRef = useRef()
  const containerRef = useRef()

  const dispatch = useDispatch()

  const { bathrooms, squareFeet, page, pets, url, yearBuilt } = useSelector((state) => state?.search)
  const { bathroomsInput, squareFeetInput, yearBuiltInput, moreContainer } = useSelector((state) => state.search)

  const setBathrooms = (payload) => dispatch(searchActionReducer?.setBathrooms(payload))
  const setSquareFeet = (payload) => dispatch(searchActionReducer?.setSquareFeet(payload))
  const setYearBuilt = (payload) => dispatch(searchActionReducer?.setYearBuilt(payload))
  const setPets = (payload) => dispatch(searchActionReducer?.setPets(payload))
  const setBathroomsInput = (payload) => dispatch(searchActionReducer.setBathroomsInput(payload))
  const setSquareFeetInput = (payload) => dispatch(searchActionReducer.setSquareFeetInput(payload))
  const setYearBuiltInput = (payload) => dispatch(searchActionReducer.setYearBuiltInput(payload))

  const [moreFilterIsOpen, setMoreFilterIsOpen] = useState(false)

  const bathroomOptions = [
    { label: '1 or More', value: 1 },
    { label: '1.5 or More', value: 1.5 },
    { label: '2 or More', value: 2 },
    { label: '2.5 or More', value: 2.5 },
    { label: '3 or More', value: 3 },
    { label: '3.5 or More', value: 3.5 },
    { label: '4 or More', value: 4 },
  ]

  const squareFeetOptions = [
    { label: '500+', value: 500 },
    { label: '600+', value: 600 },
    { label: '700+', value: 700 },
    { label: '800+', value: 800 },
    { label: '900+', value: 900 },
    { label: '1000+', value: 1000 },
    { label: '1250+', value: 1250 },
    { label: '1500+', value: 1500 },
    { label: '2000+', value: 2000 },
    { label: '2500+', value: 2500 },
    { label: '3000+', value: 3000 },
    { label: '3500+', value: 3500 },
    { label: '4000+', value: 4000 },
    { label: '5000+', value: 5000 },
  ]

  const yearBuiltOptions = [
    { label: '1980', value: 1980 },
    { label: '1990', value: 1990 },
    { label: '2000', value: 2000 },
    { label: '2005', value: 2005 },
    { label: '2010', value: 2010 },
    { label: '2012', value: 2012 },
    { label: '2014', value: 2014 },
    { label: '2016', value: 2016 },
    { label: '2018', value: 2018 },
    { label: '2020', value: 2020 },
    { label: '2022', value: 2022 },
    { label: '2023', value: 2023 },
  ]

  const petOptions = [
    { label: 'Cats OK', value: 'Cats OK' },
    { label: 'Dogs OK', value: 'Dogs OK' },
    { label: 'All Pets', value: 'Pets Allowed' },
  ]

  const handlePetsOnClick = (option, isSelected) =>
    setPets(isSelected ? pets?.filter((pet) => pet !== option?.value) : [...pets, option?.value])

  const handleCloseIconOnClick = () => {
    setSquareFeet(null)
    setBathrooms(null)
    setYearBuilt(null)
    setPets([])
    setSquareFeetInput('')
    setBathroomsInput('')
    setYearBuiltInput('')
    setMoreFilterIsOpen(false)
  }

  const handleMoreFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setMoreFilterIsOpen((moreFilterIsOpen) => !moreFilterIsOpen)
  }

  const petCheckboxes = petOptions?.map((option) => {
    const isSelected = pets?.includes(option?.value)
    return (
      <MoreOption key={option?.label} onClick={() => handlePetsOnClick(option, isSelected)} isSelected={isSelected}>
        <MoreOptionCheckbox checked={isSelected} readOnly />
        <MoreOptionLabel>{option?.label}</MoreOptionLabel>
      </MoreOption>
    )
  })

  useOutsideClick(
    containerRef,
    () => {
      if (!moreFilterIsOpen) return
      setMoreFilterIsOpen(false)
    },
    [bathrooms, moreFilterIsOpen, page, pets, squareFeet, url, yearBuilt],
  )

  return (
    <MoreFilterContainer ref={containerRef} isOpen={moreFilterIsOpen}>
      <MoreFilterTriggerContainer onClick={handleMoreFilterOnClick}>
        <ContainerTitle>More</ContainerTitle>
        <MoreFilterDropdown
          title="Square Feet"
          placeholder="Any"
          inputValue={squareFeetInput}
          setInputValue={setSquareFeetInput}
          moreValue={squareFeet}
          setMoreValue={setSquareFeet}
          options={squareFeetOptions}
        />
        <MoreFilterDropdown
          title="Bathrooms"
          placeholder="Any"
          inputValue={bathroomsInput}
          setInputValue={setBathroomsInput}
          moreValue={bathrooms}
          setMoreValue={setBathrooms}
          options={bathroomOptions}
        />
        <MoreFilterDropdown
          title="Year Built"
          placeholder="In or After"
          inputValue={yearBuiltInput}
          setInputValue={setYearBuiltInput}
          moreValue={yearBuilt}
          setMoreValue={setYearBuilt}
          options={yearBuiltOptions}
        />
        <MoreCheckboxesContainer>
          <MoreOptionSubtitle>Pets</MoreOptionSubtitle>
          {petCheckboxes}
        </MoreCheckboxesContainer>
      </MoreFilterTriggerContainer>
    </MoreFilterContainer>
  )
}

export default MoreFilter
