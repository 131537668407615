import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import amenityOptions from '../../../../constants/amenities'
import useOutsideClick from '../../../../hooks/outsideClick'
import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ContainerTitle } from '../Containers/Containers.style'
import {
  AmenitiesCheckboxesContainer,
  AmenitiesTriggerContainer,
  AmenitiesFilterContainer,
  AmenityOption,
  AmenityOptionCheckbox,
  AmenityOptionLabel,
} from './AmenitiesFilter.style'

const AmenitiesFilter = () => {
  const closeIconRef = useRef()
  const containerRef = useRef()

  const dispatch = useDispatch()

  const [amenitiesFilterIsOpen, setAmenitiesFilterIsOpen] = useState(false)

  const { amenities, page, url } = useSelector((state) => state?.search)

  const setAmenities = (payload) => dispatch(searchActionReducer?.setAmenities(payload))

  const handleCloseIconOnClick = () => {
    setAmenities([])
    setAmenitiesFilterIsOpen(false)
  }

  const handleAmenityOnClick = (option, isSelected) =>
    setAmenities(isSelected ? amenities?.filter((amenity) => amenity !== option?.value) : [...amenities, option?.value])

  const handleAmenitiesFilterOnClick = (event) => {
    if (closeIconRef?.current?.contains(event?.target)) return handleCloseIconOnClick()
    setAmenitiesFilterIsOpen((amenitiesFilterIsOpen) => !amenitiesFilterIsOpen)
  }

  useOutsideClick(
    containerRef,
    () => {
      if (!amenitiesFilterIsOpen) return
      setAmenitiesFilterIsOpen(false)
    },
    [amenities, amenitiesFilterIsOpen, page, url],
  )

  const amenityCheckboxes = amenityOptions?.map((option) => {
    const isSelected = amenities?.includes(option?.value)
    return (
      <AmenityOption
        key={option?.label}
        onClick={() => handleAmenityOnClick(option, isSelected)}
        isSelected={isSelected}
      >
        <AmenityOptionCheckbox checked={isSelected} readOnly />
        <AmenityOptionLabel>{option?.label}</AmenityOptionLabel>
      </AmenityOption>
    )
  })

  return (
    <AmenitiesFilterContainer ref={containerRef} isOpen={amenitiesFilterIsOpen}>
      <AmenitiesTriggerContainer onClick={handleAmenitiesFilterOnClick}>
        <ContainerTitle>Amenities</ContainerTitle>
        <AmenitiesCheckboxesContainer>{amenityCheckboxes}</AmenitiesCheckboxesContainer>
      </AmenitiesTriggerContainer>
    </AmenitiesFilterContainer>
  )
}

export default AmenitiesFilter
