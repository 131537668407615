import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import useAxios from 'axios-hooks'
import ReactGA from 'react-ga4'

import { FLOORS_ICON, UNIT_ICON, PYRAMID_ICON } from '../../../../../constants/image.const'
import { CONSUMER_SAVED_BUILDINGS } from '../../../../../constants/api'
import { ReactComponent as LOCATIN_ICON } from '../../../../../assets/images/marker_location.svg'
import { selectionsActionReducer } from '../../../../../store/selections/selectionsSlice'
import { modalsActionReducer } from '../../../../../store/modals/modalsSlice'
import { pageActionReducer } from '../../../../../store/page/pageSlice'
import FeaturedIcon from './FeaturedIcon'
import Images from './Images'
import {
  FavoriteContainer,
  Icon,
  IconImage,
  IconsContainer,
  IsFeaturedContainer,
  ItemContainer,
  ItemDataContainer,
  ItemLowerHalfContainer,
  ItemSubtitle,
  MapIcon,
  TextElement,
} from './Item.style'
import clickEvents from '../../../../../services/analytics.service'

function groupAndSortAddresses(addresses) {
  // Create an object to store addresses grouped by streetName
  const groupedAddresses = {}

  // Loop through the addresses
  addresses?.forEach((address) => {
    const streetName = address.streetName

    // If the streetName is not already a key in the groupedAddresses object, create an empty object for it
    if (!groupedAddresses[streetName]) {
      groupedAddresses[streetName] = {
        addresses: [],
        minNumber: Infinity,
        maxNumber: -Infinity,
      }
    }

    // Push the address into the corresponding group
    groupedAddresses[streetName].addresses.push(address)

    // Update min and max numbers
    if (address.streetNumber < groupedAddresses[streetName].minNumber) {
      groupedAddresses[streetName].minNumber = address.streetNumber
    }
    if (address.streetNumber > groupedAddresses[streetName].maxNumber) {
      groupedAddresses[streetName].maxNumber = address.streetNumber
    }
  })

  // Sort addresses within each group by streetNumber
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      groupedAddresses[streetName].addresses.sort((a, b) => a.streetNumber - b.streetNumber)
    }
  }

  // Convert the groupedAddresses object to the desired format
  const formattedAddresses = []
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      const { addresses, minNumber, maxNumber } = groupedAddresses[streetName]
      formattedAddresses.push({
        streetName: streetName,
        addresses: addresses,
        minNumber: minNumber,
        maxNumber: maxNumber,
      })
    }
  }

  return formattedAddresses
}

const Item = ({ getFavorites, building, map, savedBuilding }) => {
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)

  const setBuilding = (payload) => dispatch(selectionsActionReducer.setBuilding(payload))
  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const setCurrentMobileView = (payload) => dispatch(pageActionReducer?.setSearchPage(payload))

  const [favoriteIsLoading, setFavoriteIsLoading] = useState(false)
  const [isFavorited, setIsFavorited] = useState(!!savedBuilding?.uuid)

  const userIsLoggedIn = !!user?.user

  const addresses = groupAndSortAddresses(building?.addresses)

  const extraAddresses = addresses?.length > 3
  const addressCards = addresses?.slice(0, 2).map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]
    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  const handleShowOnMapOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const buildingUuid = building?.uuid
    const center = [building?.coordinates?.[1], building?.coordinates?.[0]]

    setBuilding(buildingUuid)

    if (window.innerWidth <= 768) setCurrentMobileView('map')
    if (map?.getBounds()?.contains(center) && map.getZoom() === 18) return
    if (map?.getBounds()?.contains(center) && map.getZoom() <= 16)
      return map?.flyTo(center, 18, {
        duration: 2.0,
      })
    map?.flyTo(center, 18, {
      duration: 1,
    })

    ReactGA.event({
      category: 'action',
      action: 'new-developments-page-building-card-show-on-map-clicked',
    })
    clickEvents.SHOW_ON_MAP()
  }

  const [, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_BUILDINGS,
      method: 'POST',
      data: {
        building: building?.uuid,
      },
    },
    { manual: true },
  )

  const [, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_BUILDINGS}${savedBuilding?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const handleItemOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'new-development-page-building-card-clicked',
    })
    clickEvents.LISTING_CARD()
  }

  const handleFavoriteOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!user) return openAuthModal()
    if (favoriteIsLoading) return
    setFavoriteIsLoading(true)
    if (isFavorited)
      removeFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(false)
        getFavorites(CONSUMER_SAVED_BUILDINGS).then(() => setFavoriteIsLoading(false))
      })
    else
      addFavorite().then((rs) => {
        setFavoriteIsLoading(false)
        setIsFavorited(true)
        getFavorites(CONSUMER_SAVED_BUILDINGS).then(() => setFavoriteIsLoading(false))
      })
  }

  useEffect(() => setIsFavorited(!!savedBuilding?.uuid), [savedBuilding])

  return (
    <ItemContainer>
      <Link to={building?.url} onClick={handleItemOnClick}>
        {building?.isFeatured && (
          <IsFeaturedContainer>
            <FeaturedIcon />
            Featured
          </IsFeaturedContainer>
        )}
        <FavoriteContainer onClick={handleFavoriteOnClick} disabled={favoriteIsLoading}>
          {favoriteIsLoading ? (
            <Loader size="mini" inline="centered" active color="white" />
          ) : userIsLoggedIn && isFavorited ? (
            <FavoriteIcon fontSize="small" />
          ) : (
            <FavoriteBorderIcon fontSize="small" />
          )}
        </FavoriteContainer>
        <Images building={building} />
        <ItemLowerHalfContainer>
          <ItemDataContainer>
            <TextElement>
              <ItemSubtitle>{building?.name}</ItemSubtitle>
              <span>
                {extraAddresses ? addressCards.slice(0, 2) : addressCards}
                {extraAddresses && <span>+{addresses?.length - 2} locations</span>}
              </span>
            </TextElement>
          </ItemDataContainer>
          <IconsContainer>
            <Icon>
              <IconImage src={UNIT_ICON} alt="unit-icon" />
              {building?.numberOfUnits} Units
            </Icon>
            <Icon>
              <IconImage src={FLOORS_ICON} alt="bath-icon" />
              {building?.numberOfFloors} Floors
            </Icon>
            <Icon>
              <IconImage src={PYRAMID_ICON} alt="square-foot-icon" />
              Arriving {building?.yearBuilt}
            </Icon>
            <MapIcon onClick={handleShowOnMapOnClick}>
              <LOCATIN_ICON />
            </MapIcon>
          </IconsContainer>
        </ItemLowerHalfContainer>
      </Link>
    </ItemContainer>
  )
}

export default Item
