import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAxios from 'axios-hooks'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { Helmet } from 'react-helmet'

import findSavedSearch from '../../services/findSavedSearch.service'
import { searchActionReducer } from '../../store/search/searchSlice'
import { generateSearchURLs } from '../../services/searchParams.service'
import { selectionsActionReducer } from '../../store/selections/selectionsSlice'
import {
  CONSUMER_SAVED_LISTINGS,
  CONSUMER_SAVED_SEARCHES,
  PUBLIC_FEATURED_LISTING_PREVIEWS,
  PUBLIC_LISTING_GROUPS,
  PUBLIC_LISTING_PREVIEWS,
} from '../../constants/api'
import Map from '../Map'
import Filters from './Filters'
import List from './List'
import SubHeader from './SubHeader'
import { SearchContainer } from './Search.style'
import neighborhoodOptions from '../../constants/neighborhoods'
import clickEvents from '../../services/analytics.service'

const Search = () => {
  const dispatch = useDispatch()

  const activeParams = useSelector((state) => state.search)
  const { user } = useSelector((state) => state.auth)
  const { filterPanelIsOpen, orderingPanelIsOpen } = useSelector((state) => state.panels)

  const resetSelectedBuilding = () => dispatch(selectionsActionReducer?.setBuilding(null))
  const setCount = (payload) => dispatch(searchActionReducer?.setCount(payload))
  const setNextUrl = (payload) => dispatch(searchActionReducer?.setNextUrl(payload))
  const setPage = (payload) => dispatch(searchActionReducer?.setPage(payload))
  const setPreviousUrl = (payload) => dispatch(searchActionReducer?.setPreviousUrl(payload))
  const setSavedSearch = (payload) => dispatch(searchActionReducer?.setSavedSearch(payload))
  const setUrl = (payload) => dispatch(searchActionReducer?.setUrl(payload))

  const userIsLoggedIn = !!user?.user

  const [map, setMap] = useState(null)

  const [{ data: listingsPreviewsResponse, loading: loadingListingsPreviewsResponse }, getListingPreviews] = useAxios(
    PUBLIC_LISTING_PREVIEWS,
    { manual: true },
  )
  const [{ data: listingsGroupsResponse, loading: loadingListingsGroupsResponse }, getListingGroups] = useAxios(
    PUBLIC_LISTING_GROUPS,
    { manual: true },
  )
  const [{ data: featuredListingsResponse, loading: loadingFeaturedListingsResponse }, getFeaturedListings] = useAxios(
    PUBLIC_FEATURED_LISTING_PREVIEWS,
    { manual: true },
  )
  const [{ data: favoritesResponse, loading: loadingFavoritesResponse }, getFavorites] = useAxios(
    CONSUMER_SAVED_LISTINGS,
    { manual: true },
  )
  const [{ data: savedSearchesResponse, loading: savedSearchesResponseIsLoading }, getSearches] = useAxios(
    CONSUMER_SAVED_SEARCHES,
    { manual: true },
  )

  const isLoading =
    !listingsGroupsResponse ||
    !listingsPreviewsResponse ||
    !featuredListingsResponse ||
    loadingListingsGroupsResponse ||
    loadingFeaturedListingsResponse ||
    loadingListingsPreviewsResponse

  const buildings = listingsGroupsResponse?.results
  const listings = listingsPreviewsResponse?.results
  const featuredListings = featuredListingsResponse?.results
  const favorites = favoritesResponse?.results
  const searches = savedSearchesResponse?.results

  const scrollToTop = () => window.scrollTo({ top: 0 })

  const refetchListings = useMemo(() => {
    return (extraParams, caller) => {
      const { featuredListingsPreviewsUrl, isNew, listingsGroupsUrl, listingsPreviewsUrl, page } = generateSearchURLs(
        activeParams,
        extraParams,
      )

      const dataLoaded = listingsPreviewsResponse || loadingListingsPreviewsResponse

      console.log(`[${page}]`, caller)

      if (!isNew && dataLoaded) return

      ReactGA.event({
        category: 'Search',
        action: 'search_conducted',
        label: listingsPreviewsUrl,
      })
      ReactPixel.track('Search', { search: listingsPreviewsUrl })
      clickEvents.SEARCH_CONDUCTED()

      setPage(page)
      setUrl(listingsPreviewsUrl)
      resetSelectedBuilding()
      scrollToTop()

      const handleErrors = (error) => {
        if (error?.code === 'ERR_CANCELED') return
        console.log(error)
      }

      getListingGroups(listingsGroupsUrl).catch(handleErrors)
      getListingPreviews(listingsPreviewsUrl)
        .catch(handleErrors)
        .then((response) => {
          setCount(response?.data?.count)
          setNextUrl(response?.data?.next)
          setPreviousUrl(response?.data?.previous)
        })
      getFeaturedListings(featuredListingsPreviewsUrl).catch(handleErrors)

      if (userIsLoggedIn) {
        getFavorites().catch(handleErrors)
        const savedSearch = findSavedSearch({ ...activeParams, ...extraParams }, searches)
        setSavedSearch(savedSearch)
      }
    }
  }, [activeParams])

  useEffect(() => {
    if (
      (listingsPreviewsResponse || loadingListingsPreviewsResponse) &&
      (featuredListingsResponse || loadingFeaturedListingsResponse) &&
      (listingsGroupsResponse || loadingListingsGroupsResponse)
    )
      return
    refetchListings({}, 'Search.useEffect [listingsPreviewsResponse, featuredListingsResponse, listingsGroupsResponse]')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingsPreviewsResponse, featuredListingsResponse, listingsGroupsResponse])

  useEffect(() => {
    if (!listingsPreviewsResponse) return // exit on first render
    if (orderingPanelIsOpen) return
    refetchListings({}, 'Search.useEffect [orderingPanelIsOpen]')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderingPanelIsOpen])

  useEffect(() => {
    if (!listingsPreviewsResponse) return // exit on first render
    if (filterPanelIsOpen) return
    refetchListings({}, 'Search.useEffect [filterPanelIsOpen]')

    const setBedsContainer = (payload) => dispatch(searchActionReducer.setBedsContainer(payload))
    const setPriceContainer = (payload) => dispatch(searchActionReducer.setPriceContainer(payload))
    const setAmenitiesContainer = (payload) => dispatch(searchActionReducer.setAmenitiesContainer(payload))
    const setMoreContainer = (payload) => dispatch(searchActionReducer.setMoreContainer(payload))

    const beds = activeParams?.beds
    const minBeds = beds?.length > 0 && Math.min(...beds)
    const maxBeds = beds?.length > 0 && Math.max(...beds)

    const bedsMessage = beds?.length
      ? minBeds === maxBeds
        ? minBeds === 4
          ? '4+ beds'
          : minBeds === 1
          ? `${minBeds} bed`
          : `${minBeds} beds`
        : maxBeds === 4
        ? `${minBeds}+ beds`
        : `${minBeds} - ${maxBeds}`
      : 'Any'

    const formatCurrency = (value) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // minimumSignificantDigits: 1,
        notation: 'compact',
        compactDisplay: 'short',
        minimumFractionDigits: value > 999 && value < 9999 ? 1 : 0,
        maximumFractionDigits: 1,
      })

    const maxPrice = activeParams?.maxPrice
    const minPrice = activeParams?.minPrice
    const minFormatted = formatCurrency(minPrice).format(minPrice)
    const maxFormatted = formatCurrency(maxPrice).format(maxPrice)

    const priceMessage =
      !!minPrice && !!maxPrice
        ? `${minFormatted} - ${maxFormatted}`
        : !!minPrice
        ? `At least ${minFormatted}`
        : !!maxPrice
        ? `${maxFormatted} or less`
        : 'Any'

    const amenities = activeParams?.amenities
    const numberOfOptionsSelected = amenities?.length
    const amenitiesMessage =
      numberOfOptionsSelected === 1
        ? '1 Option Selected'
        : numberOfOptionsSelected > 1
        ? `${numberOfOptionsSelected} Options Selected`
        : 'Select Amenities'

    const { bathrooms, pets, squareFeet, yearBuilt } = activeParams
    const petsSelected = !!pets?.length
    const selection = bathrooms || squareFeet || yearBuilt || petsSelected
    const moreMessage = selection ? '1+ Options Selected' : 'Browse Other Options'

    setBedsContainer(bedsMessage)
    setPriceContainer(priceMessage)
    setAmenitiesContainer(amenitiesMessage)
    setMoreContainer(moreMessage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPanelIsOpen])

  useEffect(() => {
    if (!userIsLoggedIn) return
    if (!searches) getSearches()
    const savedSearch = findSavedSearch(activeParams, searches)
    setSavedSearch(savedSearch)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, searches])

  const metaDescription = `Browse local listings in Miami on OceanPads. ${neighborhoodOptions
    ?.map((neighborhood) => neighborhood?.label)
    ?.join(', ')}`

  return (
    <SearchContainer>
      <Helmet>
        <title>OceanPads | Search</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://oceanpads.com/search" />
      </Helmet>
      <SubHeader refetchListings={refetchListings} savedSearchesResponseIsLoading={savedSearchesResponseIsLoading} />
      <Filters refetchListings={refetchListings} savedSearchesResponseIsLoading={savedSearchesResponseIsLoading} />
      <List
        loadingFavoritesResponse={loadingFavoritesResponse}
        favorites={favorites}
        getFavorites={getFavorites}
        featuredListings={featuredListings}
        listings={listings}
        map={map}
        isLoading={isLoading}
        refetchListings={refetchListings}
      />
      <Map buildings={buildings} isLoading={isLoading} refetchListings={refetchListings} setMap={setMap} />
    </SearchContainer>
  )
}

export default Search
