import React, { useRef, useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

import { modalsActionReducer } from '../../../../../../../store/modals/modalsSlice'
import { CONSUMER_SAVED_BUILDINGS } from '../../../../../../../constants/api'
import { ReactComponent as GROUPING_CLOSE } from '../../../../../../../assets/images/popup-close.svg'
import { selectionsActionReducer } from '../../../../../../../store/selections/selectionsSlice'
import { BUILDING_DEFAULT } from '../../../../../../../constants/image.const'
import { FLOORS_ICON, UNIT_ICON, PYRAMID_ICON } from '../../../../../../../constants/image.const'
import {
  BuildingImage,
  CloseIconContainer,
  HeaderContainer,
  HeaderTextContainer,
  Icon,
  IconImage,
  IconsContainer,
  PopupControlsContainer,
  StyledPopup,
  SubTitleContainer,
  TitleContainer,
} from './MarkerPopup.style'
import clickEvents from '../../../../../../../services/analytics.service'

function groupAndSortAddresses(addresses) {
  // Create an object to store addresses grouped by streetName
  const groupedAddresses = {}

  // Loop through the addresses
  addresses?.forEach((address) => {
    const streetName = address.streetName

    // If the streetName is not already a key in the groupedAddresses object, create an empty object for it
    if (!groupedAddresses[streetName]) {
      groupedAddresses[streetName] = {
        addresses: [],
        minNumber: Infinity,
        maxNumber: -Infinity,
      }
    }

    // Push the address into the corresponding group
    groupedAddresses[streetName].addresses.push(address)

    // Update min and max numbers
    if (address.streetNumber < groupedAddresses[streetName].minNumber) {
      groupedAddresses[streetName].minNumber = address.streetNumber
    }
    if (address.streetNumber > groupedAddresses[streetName].maxNumber) {
      groupedAddresses[streetName].maxNumber = address.streetNumber
    }
  })

  // Sort addresses within each group by streetNumber
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      groupedAddresses[streetName].addresses.sort((a, b) => a.streetNumber - b.streetNumber)
    }
  }

  // Convert the groupedAddresses object to the desired format
  const formattedAddresses = []
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      const { addresses, minNumber, maxNumber } = groupedAddresses[streetName]
      formattedAddresses.push({
        streetName: streetName,
        addresses: addresses,
        minNumber: minNumber,
        maxNumber: maxNumber,
      })
    }
  }

  return formattedAddresses
}

const MarkerPopup = ({ building, isActive, setPopUpIsOpen, isSelected }) => {
  const dispatch = useDispatch()

  let popupRef = useRef()

  const { user } = useSelector((state) => state.auth)
  const { building: selectedBuildingUuid } = useSelector((state) => state.selections)

  const [refReady, setRefReady] = useState(false)

  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const setSelectedBuildingUuid = (payload) => dispatch(selectionsActionReducer.setBuilding(payload))

  const [{ data: favoritesResponse, loading: loadingFavoritesResponse }, getFavorites] = useAxios({
    url: CONSUMER_SAVED_BUILDINGS,
    data: { building__uuid: selectedBuildingUuid },
    method: 'GET',
  })

  const buildingMedia = (!!building?.medias?.length && building?.medias[0]?.file) || BUILDING_DEFAULT
  const savedBuilding = favoritesResponse?.results?.find((e) => e.building === selectedBuildingUuid)
  const isFavorite = !!savedBuilding

  const [{ loading: loadingAddFavorite }, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_BUILDINGS,
      data: { building: selectedBuildingUuid },
      method: 'POST',
    },
    { manual: true },
  )

  const [{ loading: loadingRemoveFavorite }, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_BUILDINGS}${savedBuilding?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const saveButtonIsDisabled = loadingFavoritesResponse || loadingRemoveFavorite || loadingAddFavorite

  const addresses = groupAndSortAddresses(building?.addresses)

  const extraAddresses = addresses?.length > 3
  const addressCards = addresses?.slice(0, 2).map((address) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]
    return (
      <span key={firstAddress.uuid}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </span>
    )
  })

  const handleSaveFavorite = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!user) return openAuthModal()
    addFavorite().then(() => {
      getFavorites()
    })
  }

  const handleRemoveFavorite = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!user) return openAuthModal()
    removeFavorite().then(() => {
      getFavorites()
    })
  }

  useEffect(() => {
    if (refReady && isActive) {
      popupRef._source.openPopup()
    }
  }, [isActive, refReady])

  const handleSetRef = (r) => {
    if (isSelected && refReady && popupRef?._source) {
      if (!popupRef._source.isPopupOpen()) {
        setSelectedBuildingUuid(null)
        setRefReady(false)
        setPopUpIsOpen(false)
        popupRef = null
        return
      }
    }
    popupRef = r
    if (refReady) return
    setRefReady(true)
  }

  const handleCloseOnClick = () => {
    popupRef._source.closePopup()
    setPopUpIsOpen(false)
    setSelectedBuildingUuid(null)
    setRefReady(false)
    popupRef = null
  }

  const handleBuildingLinkOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'search-page-popup-building-image-clicked',
    })
    clickEvents.NEW_DEVELOPMENTS_POPUP_BUILDING_IMAGE()
  }

  return (
    <StyledPopup offset={[0, -35]} ref={handleSetRef}>
      <PopupControlsContainer>
        {saveButtonIsDisabled ? (
          <Loader size="mini" inline="centered" active color="blue" />
        ) : isFavorite ? (
          <FavoriteIcon fontSize="small" onClick={handleRemoveFavorite} />
        ) : (
          <FavoriteBorderIcon fontSize="small" onClick={handleSaveFavorite} />
        )}
        <CloseIconContainer onClick={handleCloseOnClick}>
          <GROUPING_CLOSE />
        </CloseIconContainer>
      </PopupControlsContainer>
      <HeaderContainer>
        <Link to={building?.url} onClick={handleBuildingLinkOnClick}>
          <HeaderTextContainer></HeaderTextContainer>
        </Link>
        <BuildingImage src={buildingMedia} />
      </HeaderContainer>

      <div
        style={{
          width: '100%',
          display: 'flex',
          padding: '10px',
          flexDirection: 'column',
          gap: '2em',
        }}
      >
        <Link to={building?.url} onClick={handleBuildingLinkOnClick}>
          <TitleContainer>{building?.name}</TitleContainer>
          <SubTitleContainer>
            {extraAddresses ? addressCards.slice(0, 2) : addressCards}
            {extraAddresses && <span>+{addresses?.length - 2} locations</span>}
          </SubTitleContainer>
        </Link>
        <IconsContainer>
          <Icon>
            <IconImage src={UNIT_ICON} alt="unit-icon" />
            {building?.numberOfUnits} Units
          </Icon>
          <Icon>
            <IconImage src={FLOORS_ICON} alt="bath-icon" />
            {building?.numberOfFloors} Floors
          </Icon>
          <Icon>
            <IconImage src={PYRAMID_ICON} alt="square-foot-icon" />
            Arriving {building?.yearBuilt}
          </Icon>
        </IconsContainer>
      </div>
    </StyledPopup>
  )
}

export default MarkerPopup
