import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { searchActionReducer } from '../../../../store/search/searchSlice'
import { FLOORS_ICON, UNIT_ICON, PYRAMID_ICON } from '../../../../constants/image.const'
import {
  AddressAndDetailsContianer,
  AddressAndNameContainer,
  AddressContainer,
  BuildingAddress,
  BuildingName,
  DetailsContainer,
  DetailsIconContainer,
  IconsContainer,
  Item,
  StyledLink,
  UnitText,
} from './AddressAndDetails.style'

function groupAndSortAddresses(addresses) {
  // Create an object to store addresses grouped by streetName
  const groupedAddresses = {}

  // Loop through the addresses
  addresses.forEach((address) => {
    const streetName = address.streetName

    // If the streetName is not already a key in the groupedAddresses object, create an empty object for it
    if (!groupedAddresses[streetName]) {
      groupedAddresses[streetName] = {
        addresses: [],
        minNumber: Infinity,
        maxNumber: -Infinity,
      }
    }

    // Push the address into the corresponding group
    groupedAddresses[streetName].addresses.push(address)

    // Update min and max numbers
    if (address.streetNumber < groupedAddresses[streetName].minNumber) {
      groupedAddresses[streetName].minNumber = address.streetNumber
    }
    if (address.streetNumber > groupedAddresses[streetName].maxNumber) {
      groupedAddresses[streetName].maxNumber = address.streetNumber
    }
  })

  // Sort addresses within each group by streetNumber
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      groupedAddresses[streetName].addresses.sort((a, b) => a.streetNumber - b.streetNumber)
    }
  }

  // Convert the groupedAddresses object to the desired format
  const formattedAddresses = []
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      const { addresses, minNumber, maxNumber } = groupedAddresses[streetName]
      formattedAddresses.push({
        streetName: streetName,
        addresses: addresses,
        minNumber: minNumber,
        maxNumber: maxNumber,
      })
    }
  }

  return formattedAddresses
}

const AddressAndDetails = ({ building }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setNeighborhoods = (payload) => dispatch(searchActionReducer.setNeighborhoods(payload))

  const type = building?.type ? building?.type?.charAt(0) + building?.type?.slice(1).toLowerCase() : 'Building'
  const units = building?.numberOfUnits
  const floors = building?.numberOfFloors
  const yearBuilt = building?.yearBuilt

  const handleNeighborhoodOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setNeighborhoods([building?.neighborhood?.uuid])
    navigate('/search')
  }

  const addresses = groupAndSortAddresses(building?.addresses)

  const addressCards = addresses?.map((address, index) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]

    return (
      <AddressContainer key={index}>
        {isRange && address.addresses?.length === 2
          ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : isRange
          ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
          : firstAddress?.value}
      </AddressContainer>
    )
  })

  return (
    <AddressAndDetailsContianer>
      <AddressAndNameContainer>
        <BuildingName>{building?.name}</BuildingName>
        <BuildingAddress>{addressCards}</BuildingAddress>
      </AddressAndNameContainer>
      <DetailsContainer>
        <UnitText>
          {type} in{' '}
          <StyledLink to={'/search'} onClick={handleNeighborhoodOnClick}>
            {building?.neighborhood?.name}
          </StyledLink>
        </UnitText>
        <IconsContainer>
          <Item>
            <DetailsIconContainer src={UNIT_ICON} alt="units" />
            {units} Units
          </Item>
          <Item>
            <DetailsIconContainer src={FLOORS_ICON} alt="floors" />
            {floors} Floors
          </Item>
          <Item>
            <DetailsIconContainer src={PYRAMID_ICON} alt="yearBuilt" />
            {building?.isANewDevelopment ? 'Arriving' : 'Built'} {yearBuilt}
          </Item>
        </IconsContainer>
      </DetailsContainer>
    </AddressAndDetailsContianer>
  )
}

export default AddressAndDetails
