import { memo, useRef, useState } from 'react'
import useAxios from 'axios-hooks'

import { CONSUMER_SAVED_BUILDING_PREVIEWS } from '../../../constants/api'
import Item from './Item'
import OrderingDropdown from './OrderingDropdown'
import useOutsideClick from '../../../hooks/outsideClick'
import Loader from './Loader'
import {
  CardsContainer,
  HeaderContainer,
  OnMarketContainer,
  OrderByContainer,
  OrderByTrigger,
  ResultsTitle,
  SavedBuildingsContainer,
} from './SavedBuildings.style'

const SavedBuildings = () => {
  const orderByRef = useRef()

  const [orderingDropdownIsOpen, setOrderingDropdownIsOpen] = useState(false)
  const [savedBuildingsOrderBy, setSavedBuildingsOrderBy] = useState('name')

  const [{ data: savedBuildingsResponse, loading: savedBuildingsResponseIsLoading }] = useAxios(
    CONSUMER_SAVED_BUILDING_PREVIEWS,
    { useCache: false },
  )

  const sortingMethods = {
    name: (a, b) => a.buildingName?.localeCompare(b.buildingName),
    yearBuilt: (a, b) => b.yearBuilt - a.yearBuilt,
  }

  const numberOfSavedBuildings = savedBuildingsResponse?.count
  const savedBuildings = savedBuildingsResponse?.results?.sort(sortingMethods[savedBuildingsOrderBy])

  const resultsTitle = numberOfSavedBuildings === 1 ? `1 Saved Building` : `${numberOfSavedBuildings} Saved Buildings`

  const handleOrderByTriggerOnClick = () => setOrderingDropdownIsOpen((isOpen) => !isOpen)

  const savedBuildingsPreviewCards = savedBuildings?.map((savedBulding) => (
    <Item building={savedBulding} key={savedBulding?.uuid} />
  ))

  useOutsideClick(orderByRef, () => setOrderingDropdownIsOpen(false), [])

  return (
    <SavedBuildingsContainer>
      <HeaderContainer>
        <OnMarketContainer></OnMarketContainer>
        <OrderByContainer ref={orderByRef} isActive={orderingDropdownIsOpen}>
          <OrderByTrigger onClick={handleOrderByTriggerOnClick}>
            <OrderingDropdown
              dropdownIsOpen={orderingDropdownIsOpen}
              setDropdownIsOpen={setOrderingDropdownIsOpen}
              savedBuildingsOrderBy={savedBuildingsOrderBy}
              setSavedBuildingsOrderBy={setSavedBuildingsOrderBy}
            />
          </OrderByTrigger>
        </OrderByContainer>
      </HeaderContainer>
      {savedBuildingsResponseIsLoading ? (
        <Loader />
      ) : (
        <>
          <ResultsTitle>{resultsTitle}</ResultsTitle>
          <CardsContainer>{savedBuildingsPreviewCards}</CardsContainer>
        </>
      )}
    </SavedBuildingsContainer>
  )
}

export default memo(SavedBuildings)
