import styled from 'styled-components'
import { Popup } from 'react-leaflet'

export const HoverWrapper = styled(Popup).attrs({
  className: 'HoverWrapper',
})`
  .leaflet-popup-content-wrapper {
    border-radius: 0 !important;
  }
  .leaflet-popup-content {
  }
`
