function groupAndSortAddresses(addresses) {
  // Create an object to store addresses grouped by streetName
  const groupedAddresses = {}

  // Loop through the addresses
  addresses?.forEach((address) => {
    const streetName = address.streetName

    // If the streetName is not already a key in the groupedAddresses object, create an empty object for it
    if (!groupedAddresses[streetName]) {
      groupedAddresses[streetName] = {
        addresses: [],
        minNumber: Infinity,
        maxNumber: -Infinity,
      }
    }

    // Push the address into the corresponding group
    groupedAddresses[streetName].addresses.push(address)

    // Update min and max numbers
    if (address.streetNumber < groupedAddresses[streetName].minNumber) {
      groupedAddresses[streetName].minNumber = address.streetNumber
    }
    if (address.streetNumber > groupedAddresses[streetName].maxNumber) {
      groupedAddresses[streetName].maxNumber = address.streetNumber
    }
  })

  // Sort addresses within each group by streetNumber
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      groupedAddresses[streetName].addresses.sort((a, b) => a.streetNumber - b.streetNumber)
    }
  }

  // Convert the groupedAddresses object to the desired format
  const formattedAddresses = []
  for (const streetName in groupedAddresses) {
    if (groupedAddresses.hasOwnProperty(streetName)) {
      const { addresses, minNumber, maxNumber } = groupedAddresses[streetName]
      formattedAddresses.push({
        streetName: streetName,
        addresses: addresses,
        minNumber: minNumber,
        maxNumber: maxNumber,
      })
    }
  }

  return formattedAddresses
}

function transformToSchemaOrg(building) {
  const addresses = groupAndSortAddresses(building?.addresses)

  const addressStrings = addresses?.map((address, index) => {
    const isRange = address?.minNumber !== address?.maxNumber
    const firstAddress = address?.addresses[0]

    return isRange && address.addresses?.length === 2
      ? `${address?.minNumber} & ${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
      : isRange
      ? `${address?.minNumber}-${address?.maxNumber} ${firstAddress.streetName}, ${firstAddress?.city}, ${firstAddress.state} ${firstAddress?.zipcode}`
      : firstAddress?.value
  })

  const type = building?.type === 'COMMUNITY' ? 'GatedResidenceCommunity' : 'ApartmentComplex'
  const address = addressStrings?.join(';')
  const buildingUrl = `https://www.oceanpads.com${building?.url}`
  const imageUrl = building?.medias?.[0]?.file?.split('?')[0]
  const lat = building?.coordinates?.[1]
  const lng = building?.coordinates?.[0]

  const schemaOrgJsonLd = {
    '@context': 'http://schema.org',
    '@type': type,
    name: building?.name,
    image: imageUrl,
    url: buildingUrl,
    address: address,
    geo: {
      '@type': 'GeoCoordinates',
      name: building?.name,
      image: imageUrl,
      url: buildingUrl,
      address: address,
      latitude: lat,
      longitude: lng,
    },
  }

  return schemaOrgJsonLd
}

export default transformToSchemaOrg
