import { useRef, useState } from 'react'

import { CloseIcon, DownArrow } from '../../Containers'
import useOutsideClick from '../../../../../hooks/outsideClick'
import {
  IconContainer,
  PriceFilterDropdownContainer,
  PriceOptionContainer,
  PriceOptionsContainer,
  PriceSubtitleContainer,
  PriceValueContainer,
  PriceValueInputContainer,
} from './PriceFilterDropdown.style'

function unformatCurrency(currencyString) {
  // Remove non-numeric characters except for '.' (decimal point)
  const numericString = currencyString.replace(/[^0-9.]/g, '')

  // Check for 'k' (thousands) or 'm' (millions) and convert them to numeric values
  let numericValue = parseFloat(numericString)
  if (currencyString.toLowerCase().includes('k')) {
    numericValue *= 1000
  } else if (currencyString.toLowerCase().includes('m')) {
    numericValue *= 1000000
  }

  return numericValue
}

const PriceFilterDropdown = ({ options, placeholder, title, priceValue, setPriceValue, inputValue, setInputValue }) => {
  const dropdownRef = useRef()
  const inputRef = useRef()

  const formatCurrency = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // minimumSignificantDigits: 1,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: value > 999 && value < 9999 ? 1 : 0,
      maximumFractionDigits: 2,
    })

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const closeIconIsHidden = !inputValue

  const handleInputOnClick = () => setDropdownIsOpen((dropdownIsOpen) => !dropdownIsOpen)

  const handleInputOnChange = (event) => {
    const value = event?.target?.value?.replace(/\D/g, '')
    setDropdownIsOpen(true)
    setInputValue(value)
  }

  const handleInputOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      inputRef?.current?.blur()
      setDropdownIsOpen(false)
    }
  }

  const handleInputOnBlur = () => {
    const newPriceValue = unformatCurrency(inputValue)
    const formattedValue = newPriceValue ? formatCurrency(newPriceValue).format(newPriceValue) : ''
    setPriceValue(newPriceValue)
    setInputValue(formattedValue)
  }

  const handleCloseIconOnClick = () => {
    inputRef?.current?.blur()
    setDropdownIsOpen(false)
    setPriceValue(null)
    setInputValue('')
  }

  const handleDownArrowOnClick = () => {
    setDropdownIsOpen(true)
  }

  const handleOptionOnClick = (option) => {
    const formattedValue = formatCurrency(option?.value).format(option?.value)
    setPriceValue(option?.value)
    setInputValue(formattedValue)
    setDropdownIsOpen(false)
  }

  useOutsideClick(
    dropdownRef,
    () => {
      setDropdownIsOpen(false)
    },
    [],
  )

  const priceOptions = options
    ?.filter((option) => !inputValue || `${option?.value}`?.includes(inputValue))
    ?.map((option) => (
      <PriceOptionContainer key={option?.value} onClick={() => handleOptionOnClick(option)}>
        {formatCurrency(option?.value).format(option.value)}
      </PriceOptionContainer>
    ))

  return (
    <PriceFilterDropdownContainer ref={dropdownRef}>
      <PriceSubtitleContainer>{title}</PriceSubtitleContainer>
      <PriceValueContainer>
        <PriceValueInputContainer
          ref={inputRef}
          placeholder={placeholder}
          onChange={handleInputOnChange}
          value={inputValue}
          onBlur={handleInputOnBlur}
          onKeyDown={handleInputOnKeyDown}
          onClick={handleInputOnClick}
        />
        <IconContainer isHidden={closeIconIsHidden} onClick={handleCloseIconOnClick}>
          <CloseIcon />
        </IconContainer>
        <IconContainer onClick={handleDownArrowOnClick}>
          <DownArrow />
        </IconContainer>
      </PriceValueContainer>
      <PriceOptionsContainer isOpen={dropdownIsOpen}>{priceOptions}</PriceOptionsContainer>
    </PriceFilterDropdownContainer>
  )
}

export default PriceFilterDropdown
