import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { searchActionReducer } from '../../../../store/search/searchSlice'
import { TypeFilterContainer, TypeFilterOption, TypeFilterSwitch, TypeFilterTriggerContainer } from './TypeFilter.style'

export const TypeFilter = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { type } = useSelector((state) => state.search)

  const setType = (payload) => dispatch(searchActionReducer.setType(payload))
  // const resetMaxPrice = () => dispatch(searchActionReducer.setMaxPrice(null))
  // const resetMinPrice = () => dispatch(searchActionReducer.setMaxPrice(null))

  const switchPosition = type === 'FOR_SALE' ? 'left' : 'right'

  const handleTypeFilerOnClick = () => {
    const value = type === 'FOR_SALE' ? 'FOR_RENT' : 'FOR_SALE'
    setType(value)
    // resetMaxPrice()
    // resetMinPrice()
    // refetchListings(
    //   {
    //     page: 1,
    //     price__gte: null,
    //     price__lte: null,
    //     type: value,
    //   },
    //   'TypeFilter.handleTypeFilerOnClick',
    // )
  }

  return (
    <TypeFilterContainer>
      <TypeFilterTriggerContainer onClick={handleTypeFilerOnClick}>
        <TypeFilterSwitch position={switchPosition} />
        <TypeFilterOption>Buy</TypeFilterOption>
        <TypeFilterOption>Rent</TypeFilterOption>
      </TypeFilterTriggerContainer>
    </TypeFilterContainer>
  )
}

export default TypeFilter
