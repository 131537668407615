import AddressAndDetails from './AddressAndDetails'
import Images from './Images'
import SaveAndShare from './SaveAndShare'
import TopSectionLoader from './TopSectionLoader'
import { TopSectionContainer } from './TopSection.style'

export const TopSection = ({ building, isLoading }) => {
  if (isLoading) return <TopSectionLoader />

  return (
    <TopSectionContainer>
      <Images building={building} />
      <AddressAndDetails building={building} />
      <SaveAndShare building={building} />
    </TopSectionContainer>
  )
}

export default TopSection
