import { useDispatch, useSelector } from 'react-redux'

import NeighborhoodsFilter from './NeighborhoodsFilter'
import PriceFilter from './PriceFilter'
import BedsFilter from './BedsFilter'
import AmenitiesFilter from './AmenitiesFilter'
import MoreFilter from './MoreFilter'
import { searchActionReducer } from '../../../store/search/searchSlice'
import { ReactComponent as CLOSE_ICON } from '../../../assets/images/close.svg'
import {
  DoneButton,
  HeaderTitle,
  FilterPanelBodyContainer,
  FilterPanelContainer,
  FilterPanelFooterContainer,
  FilterPanelHeaderContainer,
  ResetButton,
} from './FiltersPanel.style'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'

export const FiltersPanel = () => {
  const dispatch = useDispatch()

  const { filterPanelIsOpen } = useSelector((state) => state?.panels)

  const closeFilterPanel = () => dispatch(panelsActionReducer?.setFilterPanelIsOpen(false))

  const resetFilters = () => {
    dispatch(searchActionReducer.setAmenities([]))
    dispatch(searchActionReducer.setBathrooms(null))
    dispatch(searchActionReducer.setBeds([]))
    dispatch(searchActionReducer.setMaxPrice(null))
    dispatch(searchActionReducer.setMinPrice(null))
    dispatch(searchActionReducer.setNeighborhoods([]))
    dispatch(searchActionReducer.setPets([]))
    dispatch(searchActionReducer.setSquareFeet(null))
    dispatch(searchActionReducer.setYearBuilt(null))

    dispatch(searchActionReducer.setMaxPriceInput(''))
    dispatch(searchActionReducer.setMinPriceInput(''))
    dispatch(searchActionReducer.setBathroomsInput(''))
    dispatch(searchActionReducer.setSquareFeetInput(''))
    dispatch(searchActionReducer.setYearBuiltInput(''))

    dispatch(searchActionReducer.setBedsContainer('Any'))
    dispatch(searchActionReducer.setPriceContainer('Any'))
    dispatch(searchActionReducer.setAmenitiesContainer('Select Amenities'))
    dispatch(searchActionReducer.setMoreContainer('Browse Other Options'))
  }

  const handleResetFiltersOnClick = () => resetFilters()

  return (
    <FilterPanelContainer className="hidden-desktop" isOpen={filterPanelIsOpen}>
      <FilterPanelHeaderContainer>
        <HeaderTitle>Filters</HeaderTitle>
        <CLOSE_ICON onClick={closeFilterPanel} />
      </FilterPanelHeaderContainer>
      <FilterPanelBodyContainer>
        <NeighborhoodsFilter />
        <PriceFilter />
        <BedsFilter />
        <AmenitiesFilter />
        <MoreFilter />
      </FilterPanelBodyContainer>
      <FilterPanelFooterContainer>
        <ResetButton onClick={handleResetFiltersOnClick}>Reset All Filters</ResetButton>
        <DoneButton onClick={closeFilterPanel}>Apply Filters</DoneButton>
      </FilterPanelFooterContainer>
    </FilterPanelContainer>
  )
}

export default FiltersPanel
